













































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { cloneDeep } from "lodash";
import { NavigationGuardNext, Route } from "vue-router";

import JurisdictionForm from "@/application-manager/components/JurisdictionForm.vue";
import JurisdictionValuesTable from "@/application-manager/components/JurisdictionValuesTable.vue";
import JurisdictionRequestModel from "@/application-manager/models/JurisdictionRequestModel";
import { AppSection } from "@/shared/models";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";

@Component({
  components: {
    JurisdictionForm,
    JurisdictionValuesTable,
  },
})
export default class NewRevenueEventView extends mixins(UnsavedChangesMixin) {
  localJurisdiction = new JurisdictionRequestModel();
  isValidForm = true;

  readonly AppSection = AppSection;

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return cloneDeep(this.localJurisdiction);
  }

  get isSavingInProgress(): boolean {
    return this.$store.state.jurisdictionStore.isSavingInProgress;
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("applicationManager.jurisdiction.newJurisdictionTitle")
    );
  }

  mounted() {
    this.handleWatchingUnsavedChanges();
    this.$store.dispatch("loadInfraCommands");
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      this.currentRoutePath === from.path &&
      !this.isSavedForm
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  handleValidateForm(value: boolean) {
    this.isValidForm = value;
  }

  async handleCreate() {
    await this.$store
      .dispatch("createJurisdiction", this.localJurisdiction)
      .then((response) => {
        this.isSavedForm = true;
        this.$router.push({
          name: "jurisdiction_view",
          params: {
            jurisdictionId: response.id,
          },
        });
      });
  }
}
